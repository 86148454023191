export default {
  'home.title': 'ホーム',
  'home.share': '共有',
  'home.download': 'ダウンロード',
  'home.download_sample_book': 'サンプルブックをダウンロード',
  'home.select': '選択',
  'home.cancel': 'キャンセル',
  'home.select_all': 'すべて選択',
  'home.deselect_all': 'すべて選択解除',
  'home.export': 'エクスポート',
  'home.import': 'インポート',
  'home.upload': 'アップロード',
  'home.delete': '削除',

  'toc.title': '目次',
  'toc.library': 'ライブラリ',

  'search.title': '検索',
  'search.files.result': '{m}件中 {n}件該当',

  'annotation.title': '注釈',
  'annotation.definitions': '定義',
  'annotation.annotations': '注釈',
  'annotation.copy_as_markdown': 'Markdownとしてコピー',

  'image.title': '画像',

  'timeline.title': 'タイムライン',

  'typography.title': 'タイポグラフィ',
  'typography.scope.book': '本',
  'typography.scope.global': 'グローバル',
  'typography.page_view': 'ページ表示',
  'typography.page_view.single_page': '片ページ',
  'typography.page_view.double_page': '見開きページ',
  'typography.font_family': 'フォントファミリー',
  'typography.font_size': 'フォントサイズ',
  'typography.font_weight': 'フォントウェイト',
  'typography.line_height': '行の高さ',
  'typography.zoom': 'ズーム',

  'theme.title': 'テーマ',
  'theme.source_color': 'ソースカラー',
  'theme.background_color': '背景色',

  'settings.title': '設定',
  'settings.language': '言語',
  'settings.color_scheme': '配色',
  'settings.color_scheme.system': 'システム',
  'settings.color_scheme.light': 'ライト',
  'settings.color_scheme.dark': 'ダーク',
  'settings.synchronization.title': '同期',
  'settings.synchronization.authorize': '認証',
  'settings.synchronization.unauthorize': '認証解除',
  'settings.cache': 'キャッシュ',
  'settings.cache.clear': 'クリア',

  'menu.copy': 'コピー',
  'menu.search_in_book': '本を検索',
  'menu.annotate': '注釈',
  'menu.define': '定義',
  'menu.undefine': '未定義',
  'menu.create': '作成',
  'menu.delete': '削除',
  'menu.update': '更新',

  'action.expand_all': 'すべて展開',
  'action.collapse_all': 'すべて折りたたむ',
  'action.close': '閉じる',
  'action.clear': 'クリア',
  'action.step_down': 'ステップダウン',
  'action.step_up': 'ステップアップ',

  untitled: '無題',
} as const
